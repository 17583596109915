<template>
  <div id="page_not_found">
    <div class="nav bg-yellow-100">
      <div class="flex place-items-center w-full justify-center">
        <div class="my-auto">
          <div class="margin">
            {{ $route.path == "/access_denied" ? 401 : 404 }}
          </div>
          <div class="long margin">
            {{
              $route.path == "/access_denied"
                ? "Access Denied"
                : "Page not found"
            }}
          </div>
          <div class="back cursor-pointer" @click="$router.push('/login')">
            Back to {{ $store.getters["auth/isLoggedin"] ? "home" : "login" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "404",
};
</script>
<style lang="scss" scoped>
#page_not_found {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 52px;

  color: #032b3d;

  .margin {
    margin-bottom: 30px;
    &.long {
      margin-bottom: 60px;
    }
  }
  .flex {
    height: 100vh;
    margin-bottom: 0px;
  }
  .back {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 33px;
    /* identical to box height */

    text-decoration-line: underline;

    color: #1679a8;
  }
}
</style>

